/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import withWrapper from '../components/wrapper.js';
import { Helmet } from "react-helmet";


function _inner () {
  return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>404</title>
            <link rel="canonical" href="https://www.valtrace.com/404/" />
            <html lang="en" />
          </Helmet>
        
          <div>
            <h1><span className='hsp'>404: Not found</span></h1>
          </div>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
  );
}

export default withWrapper(_inner);